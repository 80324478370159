<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Configuration') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'sla-list' }" >
          SLA
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Create') }} SLA
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'sla-list' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <!-- content -->
    <validation-observer ref="rules">
      <!-- frist floor -->
      <b-card>
        <b-card-header>
          <b-card-title>{{ $t('General info') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="4">
              <b-form-group
                class="ll-bforms"
                :label="$t('Customer')"
                label-for="customer"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer"
                  rules="required"
                >
                  <v-select
                    id="customer"
                    :options="customerList"
                    label="name"
                    @input="getCustomer"
                    placeholder=""
                    v-model="customer"
                  ></v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                class="ll-bforms"
                :label="$t('SLA ID')"
                label-for="slaId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="SLA ID"
                  rules="required"
                >
                  <b-form-input
                    id="slaId"
                    v-model="baseInfo.slaName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                class="ll-bforms"
                :label="$t('Description')"
                label-for="des"
              >
                <b-form-input
                  id="des"
                  v-model="baseInfo.description"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4">
              <b-form-group
                class="ll-bforms"
                :label="$t('Effective date')"
                label-for="effectiveDate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Effective date"
                  rules="required"
                >
                  <flat-pickr
                    id="effectiveDate"
                    v-model="baseInfo.effectiveDate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss'}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                class="ll-bforms"
                :label="$t('Work date only')"
                label-for="workdayFlag"
              >
                <v-select
                  id="workdayFlag"
                  :options="workdayList"
                  label="name"
                  @input="getWorkDay"
                  placeholder=""
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                class="ll-bforms"
                :label="$t('Timezone')"
                label-for="timezone"
              >
                <v-select
                  id="timezone"
                  :options="timezoneList"
                  label="name"
                  @input="getTimezone"
                  placeholder=""
                ></v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <!-- second floor -->
      <b-card>
        <b-card-header>
          <b-card-title>{{ $t('Status list') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col lg="3">
              <b-form-group
                class="ll-bforms"
                :label="$t('Clock start status')"
                label-for="startStatus"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Clock start status"
                  rules="required"
                >
                  <v-select
                    id="startStatus"
                    :options="statusList"
                    label="statusNo"
                    @input="getStartStatus"
                    placeholder=""
                    v-model="startStatus"
                  >
                    <template #option="{ statusNo, statusDesc }">
                      <span class="ml-50 align-middle"> {{ statusNo }} -</span>
                      <span class="ml-50 align-middle"> {{ statusDesc}}</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3">
              <b-form-group
                class="ll-bforms"
                :label="$t('Clock stop status')"
                label-for="stopStatus"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Clock stop status"
                  rules="required"
                >
                  <v-select
                    id="stopStatus"
                    multiple
                    :options="statusList1"
                    label="name"
                    @input="getStopStatus"
                    placeholder=""
                    v-model="stopStatus"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-textarea
                v-model="textArea1"
                rows="5"
                trim
                class="ll-tArea"
              />
            </b-col>
            <b-col lg="3">
              <b-form-group
                class="ll-bforms"
                :label="$t('SLA due')"
                label-for="slaDue"
              >
                <v-select
                  id="slaDue"
                  :options="slaDueList"
                  placeholder=""
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                class="ll-bforms"
                :label="$t('Day')"
                label-for="day"
              >
                <b-form-input
                  id="day"
                  v-model="day"
                  type="number"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                class="ll-bforms"
                :label="$t('Hour')"
                label-for="hour"
              >
                <b-form-input
                  id="hour"
                  v-model="hour"
                  type="number"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3">
              <b-form-group
                class="ll-bforms"
                :label="$t('Tracking only')"
                label-for="trackingOnly"
              >
                <v-select
                  id="trackingOnly"
                  multiple
                  :options="statusList2"
                  label="name"
                  @input="getTrackingOnly"
                  placeholder=""
                  v-model="trackingOnly"
                >
                </v-select>
              </b-form-group>
              <b-form-textarea
                v-model="textArea2"
                rows="5"
                trim
                class="ll-tArea"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </validation-observer>
    <div class="ll-subBtn">
      <b-button
        variant="info"
        @click.prevent="validationForm"
      >
        {{ $t('Submit') }}
      </b-button>
    </div>
  </section>
</template>

<script>
import {
  BBreadcrumb,BBreadcrumbItem, BButton, BImg,
  BRow, BCol,
  BCard, BCardHeader, BCardTitle, BCardBody,
  BFormGroup, BInputGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    required,
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BInputGroup,
    BFormInput,
    flatPickr,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      baseInfo: {
        effectiveDate: '',
        endStatus: [],
        workdayFlag: '',
        hours: 0,
        orgId: '',
        slaName: '',
        startStatus: '',
        timezone: '',
        isEnabled: '',
        description: '',
        trackings: [],
      },
      customer: '',
      customerList: [],
      workdayList: [
        { name: 'Yes', key: true },
        { name: 'No', key: false },
      ],
      timezoneList: [],
      statusList: [],
      startStatus: '',
      stopStatus: '',
      slaDueList: ['By start time', 'By due time'],
      day: '',
      hour: '',
      statusList1: [],
      textArea1: '',
      trackingOnly: '',
      textArea2: '',
      statusList2: [],
    }
  },
  created() {},
  mounted() {
    this.queryCustomer()
    this.queryTimezone()
    this.queryStatus()
  },
  methods: {
    queryCustomer() {
      this.$http.get('/admin/settings/organization/findAllOrganization').then(res => {
        this.customerList = res.data.data
      })
    },
    getCustomer(s) {
      this.customer = s.name
      this.baseInfo.orgId = s.orgCode
    },
    getWorkDay(s) {
      this.baseInfo.workdayFlag = s.key
    },
    queryTimezone() {
      this.$http.get('slaConfig/getTimeZoneList').then(res => {
        this.timezoneList = res.data.data
      })
    },
    getTimezone(s) {
      this.baseInfo.timezone = s.value
    },
    queryStatus() {
      this.$http.get('statusManagement/getIntCodeList').then(res => {
        this.statusList = res.data.data
        const arr = []
        const a = res.data.data
        a.forEach(i => {
          const obj = {}
          obj.name = `${i.statusNo} ${i.statusDesc}`
          obj.intCode = i.intCode
          arr.push(obj)
        })
        this.statusList1 = arr
        this.statusList2 = arr
      })
    },
    getStartStatus(s) {
      this.startStatus = `${s.statusNo} ${s.statusDesc}`
      this.baseInfo.startStatus = s.intCode
    },
    getStopStatus(s) {
      this.stopStatus = s
      const arr1 = []
      const arr2 = []
      s.map(i =>{
        arr1.push(i.name)
        arr2.push(i.intCode)
      })
      this.textArea1 = arr1.toString().replace(/[\,]/g,'\n')
      this.baseInfo.endStatus = arr2.toString()
    },
    getTrackingOnly(s){
      this.trackingOnly = s
      const arr1 = []
      const arr2 = []
      s.map(i =>{
        arr1.push(i.name)
        arr2.push(i.intCode)
      })
      this.textArea2 = arr1.toString().replace(/[\,]/g,'\n')
      this.baseInfo.trackings = arr2.toString()
    },
    createSla() {
      let time = (parseInt(this.day))*24 + parseInt(this.hour)
      this.baseInfo.hours = time
      this.$http.post('slaConfig/add', this.baseInfo).then(res => {
        if (res.data.code === '200') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Create SLA Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'sla-list' })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    // 创建前验证
    validationForm() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.createSla()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
  },
}
</script>
<style scoped>
/* .ll-bforms{
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.ll-tArea{
  margin-bottom: 1rem;
  width: 70%;
  /* float: right; */
}
.ll-subBtn{
  text-align: right;
  height: 50px;
}
</style>
<style>
.ll-bforms>label{
  width: 30%;
}
.ll-bforms>div{
  width: 70%;
}
</style>